export const Partners = (props) => {
  const jsonData = [
    {
      id: 1,
      name: 'ACCURACY RELOADING',
      logo: 'https://www.accuracyreloading.it/new/wp-content/uploads/2016/12/ll.png',
      url: 'https://www.accuracyreloading.it'
    },
    // {
    //   id: 2,
    //   name: 'ARMERIA REGINA',
    //   logo: 'https://armeriaregina.it/img/armeria-regina-logo-1618311059.jpg',
    //   url: 'https://armeriaregina.it/gb/'
    // },
    {
      id: 3,
      name: 'DRÔME CHASSETIR',
      logo: 'https://dromechassetir.com/wp-content/uploads/2021/11/logo-drome-chasse-tir-armurerie-en-ligne-chasse-et-tir.png',
      url: 'https://dromechassetir.com'
    }
  ]
  
  return (
    <section id='partners' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Partners</h2>
        </div>
        <div className='row mt-5 align-items-center justify-content-center gap-4'>
          {
            jsonData.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-lg-3 col-md-4 col-sm-6 team'>
                <a 
                  key={d.id} 
                  href={d.url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="partner-item"
                >
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.logo} alt='...' className='partners-img' />
                  </div>
                </a>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}