import React, { useState } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';

export const AddressDropDown = (params) => {
    const { choiceKey, choiceValue, choices, onClick } = params
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="#" ref={ref} onClick={(e) => {
            e.preventDefault();
            onClick(e);
            }}
        >
            {children}
            &nbsp;⏷
        </a>
    ));
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');
            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <FormControl
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                        !value || child.props.children.toLowerCase().includes(value.toLowerCase()),
                    )}
                    </ul>
                </div>
            );
        },
    );
    let displayValue = choiceValue 
    if (typeof choiceValue !== 'undefined' && choiceValue !== null) {
        const foundChoices = choices.filter(choice => choice.abbrev.toLowerCase() === choiceValue.toLowerCase())
        if (foundChoices.length > 0) {
            displayValue = foundChoices[0].name
        }
    }
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle}>
                {displayValue}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
                {
                    choices.map(choice => {
                        return (
                            <Dropdown.Item
                                key={choice.abbrev}
                                active={choice.abbrev === choiceValue}
                                name={choiceKey}
                                value={choice.abbrev}
                                onClick={(e) => {
                                    e.target.value = choice.abbrev
                                    onClick(e)
                                }}>
                                {choice.name}
                            </Dropdown.Item>
                        )
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}